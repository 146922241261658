<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                      <b-tr>
                        <b-th style="width:20%">{{ $t('elearning_config.fiscal_year') }}</b-th>
                        <b-td style="width:80%">{{ ($i18n.locale=='bn') ? venueCondition.fiscal_year_bn : venueCondition.fiscal_year }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:20%">{{ $t('org_pro.organization') }}</b-th>
                        <b-td style="width:80%">{{ ($i18n.locale=='bn') ? venueCondition.org_bn : venueCondition.org }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:20%">{{ $t('elearning_config.condition') }}</b-th>
                        <b-td v-if="$i18n.locale=='en'" style="width:80%">
                          <span v-html="venueCondition.condition_en"></span>
                        </b-td>
                        <b-td v-else style="width:80%">
                          <span v-html="venueCondition.condition_bn"></span>
                        </b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.venueCondition = this.item
  },
  data () {
    return {
      venueCondition: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
